<template>
  <div>
    <NuxtLayout
      name="teasers"
      v-bind="{ loading }"
      :no-results="!teasers.length"
    >
      <ScratchcardTeaser
        v-for="(teaser, index) in teasers"
        :key="index"
        v-bind="teaser"
      />

      <template #no-results>
        <LcNoResults
          src="/assets/img/illustration/sold-out.svg"
          variant="outlined"
        >
          <template #headline>
            {{ $t('playnow.tab.scratchcards.noresults') }}
          </template>
        </LcNoResults>
      </template>
    </NuxtLayout>
  </div>
</template>

<script lang="ts" setup>
import { LcNoResults } from '@lottocom/frontend-components'

type PlayNowScratchcardsProps = {
  pollInterval?: number
}

const props = withDefaults(defineProps<PlayNowScratchcardsProps>(), {
  pollInterval: 3_000_000,
})

const licensedTerritory = useLicensedTerritory({ raw: true })

const { loading, result } = useScratchcardTeasersQuery(
  { licensedTerritory },
  {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    pollInterval: props.pollInterval,
  },
)

const teasers = computed(
  () =>
    result.value?.scratchcardTeasers.map((teaser) => ({
      ...teaser,
      amount: teaser.value,
      price: teaser.pricePerCard,
    })) || [],
)
</script>

<style lang="scss" scoped>
.no-results {
  min-width: calc(100vw - #{spacing('md')});
  border: 0.0625rem solid color('secondary600');

  p {
    white-space: pre-wrap;
  }

  @include media-desktop {
    min-width: 47rem;
  }
}
</style>
